import { Variants } from 'framer-motion';
import React from 'react';
import s from './TitleContainer.styles';

export interface TitleContainerProps {
  align?: 'left' | 'center' | 'right';
  subtitle?: string;
  title?: string;
}

const TitleContainer: React.FC<TitleContainerProps> = ({
  align = 'center',
  subtitle,
  title,
  ...otherProps
}) => {
  if (!title && !subtitle) {
    return null;
  }

  const containerVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
        staggerDirection: -1,
      },
    },
  };

  const titleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const subtitleVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <s.Container align={align} initial="hidden" variants={containerVariants} {...otherProps}>
      {title && (
        <s.Title initial="hidden" variants={titleVariants}>
          {title}
        </s.Title>
      )}
      {subtitle && (
        <s.Subtitle initial="hidden" variants={subtitleVariants}>
          {subtitle}
        </s.Subtitle>
      )}
    </s.Container>
  );
};

export default TitleContainer;
