import React, { ReactNode } from 'react';
import s from './LinkUnderline.styles';

export interface LinkUnderlineProps extends ReactAnchorProps {
  children: ReactNode;
}

export const LinkUnderline: React.FC<LinkUnderlineProps> = ({ children, href, ...otherProps }) => {
  const internal = href && /^\/(?!\/)/.test(href);

  if (!href) {
    return (
      <s.Wrapper as="span" {...otherProps}>
        <s.Text>{children}</s.Text>
        <s.UnderlineWrapper>
          <s.UnderlinePrimary />
          <s.UnderlineSecondary />
        </s.UnderlineWrapper>
      </s.Wrapper>
    );
  }

  if (internal && href) {
    return (
      <s.Wrapper to={href} {...otherProps}>
        <s.Text>{children}</s.Text>
        <s.UnderlineWrapper>
          <s.UnderlinePrimary />
          <s.UnderlineSecondary />
        </s.UnderlineWrapper>
      </s.Wrapper>
    );
  }

  return (
    <s.Wrapper as="a" {...otherProps}>
      <s.Text>{children}</s.Text>
      <s.UnderlineWrapper>
        <s.UnderlinePrimary />
        <s.UnderlineSecondary />
      </s.UnderlineWrapper>
    </s.Wrapper>
  );
};

export interface ButtonUnderlineProps extends ReactButtonProps {
  children: ReactNode;
}

export const ButtonUnderline: React.FC<ButtonUnderlineProps> = ({ children, ...otherProps }) => {
  return (
    <s.Wrapper as="button" {...otherProps}>
      <s.Text>{children}</s.Text>
      <s.UnderlineWrapper>
        <s.UnderlinePrimary />
        <s.UnderlineSecondary />
      </s.UnderlineWrapper>
    </s.Wrapper>
  );
};
