import SvgWaveMulti from '!babel-loader!react-svg-loader!@img/wave-multi.svg';
import { markdownStyles } from '@utils/markdown';
import { Device, from } from '@utils/media';
import scrollbar from '@utils/scrollbar';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(4, 1fr);

  @media ${from(Device.TabletLarge)} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Wave = styled(SvgWaveMulti)`
  position: absolute;
  display: none;
  color: ${theme.brand.blue.dark};
  height: 118px;
  width: 232px;

  @media ${from(Device.TabletLarge)} {
    display: block;
  }
`;

const Wave1 = styled(Wave)`
  bottom: 128px;
  left: -48px;
`;

const Wave2 = styled(Wave)`
  top: 128px;
  right: -48px;
`;

const Wave3 = styled(Wave)`
  display: block;
  top: 128px;
  right: -48px;
  height: 83px;
  width: 162px;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  grid-column: 1 / span 4;
  padding: 80px 0 0;

  @media ${from(Device.TabletLarge)} {
    grid-column: 1 / span 5;
  }

  @media ${from(Device.DesktopSmall)} {
    grid-column: 1 / span 4;
  }

  @media ${from(Device.DesktopLarge)} {
    grid-column: 1 / span 3;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const Title = styled(motion.h2)`
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 16px;
  max-width: 400px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 32px;
  }
`;

const Subtitle = styled(motion.h3)`
  color: ${theme.brand.red.default};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  max-width: 400px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 18px;
    margin-bottom: 16px;
  }
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 22px;
  margin-bottom: 24px;
  max-width: 370px;
  text-transform: uppercase;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 26px;
  }
`;

const CallToAction = styled(motion.div)``;

const ItemsScroller = styled.div`
  display: grid;
  grid-column: 1 / span 4;
  margin: 0 -18px;
  overflow-x: auto;
  padding-bottom: 32px;

  ${scrollbar};

  @media ${from(Device.TabletLarge)} {
    margin: 0;
    grid-column: 6 / span 7;
  }

  @media ${from(Device.DesktopSmall)} {
    grid-column: 5 / span 8;
  }

  @media ${from(Device.DesktopLarge)} {
    grid-column: 4 / span 9;
  }
`;

const Items = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin-left: -14px;
  padding-bottom: 20px; // allow space for animation to avoid scrollbars

  @media ${from(Device.TabletLarge)} {
    margin: 0;
  }
`;

const Item = styled(motion.div)`
  color: ${theme.brand.blue.dark};
  flex: 1 0 261px;
  margin: 0 32px;
  text-align: center;
  text-decoration: none;
  width: 261px;

  @media ${from(Device.TabletLarge)} {
    flex-basis: 318px;
    width: 318px;

    &:nth-child(even) {
      margin-top: 56px;
    }
  }
`;

const ItemImage = styled(motion.div)`
  margin-bottom: 32px;

  @media ${from(Device.TabletLarge)} {
    margin-bottom: 48px;
  }

  img {
    border-radius: 3px;
  }
`;

const ItemTitle = styled(motion.h4)`
  color: ${theme.brand.red.default};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const ItemContent = styled(motion.div)`
  ${markdownStyles};
  ${theme.fonts.brandon.bold};
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const ItemCallToAction = styled(motion.div)``;

export default {
  CallToAction,
  Content,
  ContentContainer,
  Item,
  ItemCallToAction,
  ItemContent,
  ItemImage,
  Items,
  ItemsScroller,
  ItemTitle,
  Subtitle,
  Title,
  TitleContainer,
  Wave1,
  Wave2,
  Wave3,
  Wrapper,
};
