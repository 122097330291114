import SectionWrapper from '@presentation/SectionWrapper';
import sectionWrapperStyles, { SiteWide } from '@presentation/SectionWrapper/SectionWrapper.styles';
import { Device, from } from '@utils/media';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const StyledSectionWrapper = styled(SectionWrapper)`
  clip-path: url(#simple-banner-clip-path);

  /* Allow the next section to overlap the banner. */
  + ${sectionWrapperStyles.Wrapper} {
    margin-top: -224px;
  }
`;

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  padding: 158px 0 224px;

  @media ${from(Device.TabletLarge)} {
    padding: 224px 0 224px;
  }
`;

const StyledSiteWide = styled(SiteWide)`
  display: flex;
  flex-direction: column;
  place-content: space-evenly;
  place-items: center;
  text-align: center;
`;

const ClipPath = styled.svg`
  position: absolute;
  height: 0;
  width: 0;
`;

const TurtleImage = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  opacity: 0.1;
  pointer-events: none;
  transform: translate3d(-40%, 0, 0);
  width: 483px;

  @media ${from(Device.DesktopSmall)} {
    transform: translate3d(0, 0, 0);
  }
`;

const CallToAction = styled(motion.div)``;

export default {
  CallToAction,
  ClipPath,
  SectionWrapper: StyledSectionWrapper,
  SiteWide: StyledSiteWide,
  TurtleImage,
  Wrapper,
};
