import React from 'react';
import { Link } from '@presentation/Button';
import { ComponentThemeTaxonomy } from '@presentation/SectionWrapper';
import TitleContainer from '@presentation/TitleContainer';
import s from './SimpleBanner.styles';
import GatsbyImage, { FixedObject } from 'gatsby-image';
import { Variants } from 'framer-motion';
import useWasViewed from '@hooks/useWasViewed';

export interface SimpleBannerProps {
  title?: string;
  subtitle?: string;
  ctaUrl?: string;
  ctaNewWindow?: boolean;
  ctaText?: string;
  turtleImage?: FixedObject;
  theme?: ComponentThemeTaxonomy;
}

const SimpleBanner: React.FC<SimpleBannerProps> = ({
  ctaNewWindow = false,
  ctaText,
  ctaUrl,
  subtitle,
  title,
  turtleImage,
  theme = ComponentThemeTaxonomy.LIGHT_BLUE,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const ctaVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  return (
    <>
      <s.ClipPath>
        <clipPath id="simple-banner-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0,0 V0.901 C0.012,0.903,0.045,0.906,0.045,0.902 C0.045,0.9,0.09,0.897,0.09,0.894 C0.09,0.902,0.136,0.876,0.136,0.869 H0.136 C0.137,0.869,0.18,0.907,0.18,0.911 C0.18,0.868,0.229,0.892,0.229,0.895 C0.229,0.895,0.274,0.921,0.274,0.907 C0.274,0.91,0.316,0.933,0.316,0.938 C0.316,0.943,0.364,0.979,0.364,0.95 C0.364,0.94,0.376,0.954,0.388,0.968 C0.399,0.982,0.41,0.995,0.41,0.988 C0.41,0.982,0.464,0.942,0.464,0.963 C0.464,0.968,0.507,0.989,0.507,0.972 C0.507,0.956,0.521,0.968,0.533,0.98 C0.542,0.99,0.55,0.999,0.55,0.995 C0.55,0.99,0.594,0.966,0.594,0.975 C0.594,0.965,0.605,0.975,0.616,0.985 C0.627,0.996,0.639,1,0.639,0.994 C0.639,0.992,0.682,0.99,0.682,0.986 C0.682,0.972,0.725,0.977,0.725,0.965 C0.725,0.963,0.733,0.963,0.743,0.963 C0.755,0.964,0.768,0.964,0.768,0.959 C0.768,0.97,0.814,0.969,0.814,0.972 C0.814,0.954,0.857,0.955,0.857,0.966 C0.857,0.971,0.867,0.966,0.877,0.96 C0.889,0.955,0.901,0.949,0.901,0.957 C0.901,0.947,0.941,0.936,0.941,0.935 C0.941,0.932,0.984,0.914,0.984,0.925 C0.984,0.918,0.991,0.912,1,0.908 V0"></path>
        </clipPath>
      </s.ClipPath>
      <s.SectionWrapper ref={refInView} fade={false} fullWidth theme={theme}>
        <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
          {turtleImage && (
            <s.TurtleImage>
              <GatsbyImage fixed={turtleImage} />
            </s.TurtleImage>
          )}
          <s.SiteWide>
            <TitleContainer title={title} subtitle={subtitle} />
            {ctaUrl && (
              <s.CallToAction initial="hidden" variants={ctaVariants}>
                <Link href={ctaUrl} target={ctaNewWindow ? '_blank' : '_self'}>
                  {ctaText}
                </Link>
              </s.CallToAction>
            )}
          </s.SiteWide>
        </s.Wrapper>
      </s.SectionWrapper>
    </>
  );
};

export default SimpleBanner;
