import theme from '@utils/theme';
import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import SvgWave from '!babel-loader!react-svg-loader!@img/wave.svg';
import heroBannerStyles from '@presentation/HeroBanner/HeroBanner.styles';
import cocktailsCarouselStyles from '@presentation/CocktailsCarousel/CocktailsCarousel.styles';
import { Device, from } from '@utils/media';

const Wrapper = styled(GatsbyLink)`
  position: relative;
  display: inline-flex;
  background: none;
  border: 0;
  color: ${theme.brand.blue.default};
  cursor: pointer;
  flex-direction: column;
  ${theme.fonts.brandon.bold};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 28px;
  margin: 0;
  padding: 0;
  place-content: center;
  place-items: center;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:focus {
    outline: auto;
    outline-offset: 3px;
  }

  ${heroBannerStyles.ItemCTAs} & {
    margin: 0 12px 24px;

    @media ${from(Device.DesktopSmall)} {
      margin: 0 12px;
    }
  }

  ${cocktailsCarouselStyles.Item} & {
    color: ${theme.brand.blue.dark};
  }
`;

const Text = styled.span`
  display: block;
  margin-bottom: 2px;
  transform: translate3d(0, 0, 0);
  transition: color 0.3s ease-out, filter 0.3s ease-out, transform ease-out 0.3s;

  ${Wrapper}:focus &,
  ${Wrapper}:hover & {
    filter: brightness(1.35);
    transform: translate3d(0, -4px, 0);
  }
`;

const UnderlineWrapper = styled.span`
  display: grid;
  height: 12px;
  overflow: hidden;
  place-content: center;
  place-items: center;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 0.3s;
  will-change: transform;
`;

const underlineSharedStyles = css`
  display: block;
  grid-column: 1;
  grid-row: 1;
  transition: transform ease-out 0.3s;
  width: 90px;
  will-change: transform;
`;

const UnderlinePrimary = styled(SvgWave)`
  ${underlineSharedStyles};
  color: ${theme.brand.blue.dark};
  transform: translate3d(-100%, 0, 0) scaleX(-1);

  ${Wrapper}:focus &,
  ${Wrapper}:hover & {
    transform: translate3d(0, 0, 0) scaleX(-1);
  }
`;

const UnderlineSecondary = styled(SvgWave)`
  ${underlineSharedStyles};
  color: ${theme.brand.red.default};
  transform: translate3d(0, 0, 0);

  ${Wrapper}:focus &,
  ${Wrapper}:hover & {
    transform: translate3d(100%, 0, 0);
  }
`;

export default {
  Text,
  UnderlinePrimary,
  UnderlineSecondary,
  UnderlineWrapper,
  Wrapper,
};
