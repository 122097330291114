import { Device, from } from '@utils/media';
import theme from '@utils/theme';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export interface ContainerProps {
  align: 'left' | 'center' | 'right';
}

const Container = styled(motion.section)<ContainerProps>`
  --title-color: ${theme.brand.red.default};
  --subtitle-color: ${theme.brand.blue.dark};

  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 24px;
  place-content: center;

  ${({ align }) => {
    switch (align) {
      case 'left':
        return css`
          place-items: flex-start;
          text-align: left;
        `;

      case 'right':
        return css`
          place-items: flex-end;
          text-align: right;
        `;

      case 'center':
      default:
        return css`
          place-items: center;
          text-align: center;
        `;
    }
  }}
`;

const Title = styled(motion.h2)`
  color: var(--title-color);
  font-size: 40px;
  line-height: 45px;
  margin: 0;
  max-width: 640px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 60px;
    line-height: 70px;
  }
`;

const Subtitle = styled(motion.h3)`
  color: var(--subtitle-color);
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0 0 8px;
  max-width: 640px;
  text-transform: uppercase;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
  }
`;

export default {
  Container,
  Subtitle,
  Title,
};
