import { useEffect, useState } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

type WasViewedHookResponse = {
  ref: (node?: Element | null) => void;
  entry?: IntersectionObserverEntry;
  inView: boolean;
  wasViewed: boolean;
};

function useWasViewed(options?: IntersectionOptions): WasViewedHookResponse {
  const [wasViewed, setWasViewed] = useState(false);

  const hookResponse = useInView(options);

  useEffect(() => {
    if (hookResponse.inView) {
      setWasViewed(true);
    }
  }, [hookResponse.inView]);

  return { wasViewed, ...hookResponse };
}

export default useWasViewed;
